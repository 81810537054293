import React from "react";
import "./Loader.css";

const Loader = ({ loading }) => {
    // console.log("Loading", loading );
  if (!loading) return null; // Only render when loading is true

  return (
    <div className="backdrop">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
