import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.markercluster";
import PropTypes from "prop-types";
import ReactDOM from "react-dom/client"; // For React 1
import axios from "axios";

const PopUpContainer = ({
  name,
  setIsshowIds,
  isshowssIds,
  substationId,
  setColor,
  color,
  isChecked,
  setIsChecked,
}) => {

const[isLineCheck,setIsLineCheck] = useState(false);
  const [isCategory, setIsCategory] = useState({});
  const [lineColor, setLineColor] = useState();
  const [isApplied, setIsApplied] = useState(false);

  const handleLineCheckbox = (event) => {
    setIsApplied(false);
    // setIsChecked((prev) => ({
    //   ...prev,
    //   [id]:event.target.value,
    // }));
    setIsLineCheck(event.target.value);
  };


  useEffect(() => {
    if (isshowssIds.includes(substationId)) {
      setIsLineCheck(true);
    } else {
      setIsLineCheck(false);
    }
  }, [isshowssIds, substationId]);
  


  const handleCategoryCheckbox = (event, category) => {
    setIsApplied(false);
    setIsCategory((prev) => ({
      ...prev,
      [category]: event.target.checked,
    }));
  };

  const applyFilter = () => {
    setColor((prevColors) => ({
      ...prevColors,
      [substationId]: lineColor,
    }));
  
    if (isLineCheck) {

      // console.log("Checkpoint 1")
      // Add new substationId at the end of the array and remove the oldest one if the array exceeds a limit (e.g., 3)
      // const newFilterIds = [...isshowssIds, substationId];

      setIsshowIds((prev) => [...prev, substationId]); // Update the isshowIds state


      // if (newFilterIds.length > 3) {
      // console.log("Checkpoint 2")

      //   newFilterIds.shift(); // Remove the oldest ID to maintain FIFO
      // }
  
      // console.log("Checkpoint 1.5")

      // Remove the substationId from isChecked state
      // const newState = { ...isChecked };
      // delete newState[substationId]; // Remove the substationId from isChecked
  
      // setIsChecked(newState); // Update the isChecked state
  
    } else {
      // Remove substationId from the list if unchecked
      setIsshowIds(isshowssIds.filter((id) => id !== substationId));
      // console.log("Checkpoint -0.5")
      
    }
  
    setIsApplied(true);
  };
  

  const handleColorChange = (event) => {
    setLineColor(event.target.value);
    setIsApplied(false);
  };

  const [information, setInformation] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (substationId) {
      setLoading(true);

      axios
        .get(`https://geo-tag.nividasoftware.com/api/substation/${substationId}/categories`)
        .then((result) => {
          setInformation(result.data);
          result.data.forEach(item => {
            setIsCategory((prev) => ({
              ...prev,
              [item.category]: true
            }));
          });
          

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.error("Error fetching project information:", e);
        });
    }
  }, [substationId]);


  // console.log("Check " +isChecked)
  // console.dir(isChecked)

  return (
    <div
      style={{
        width: "full",
        height: "auto",
        padding: "10px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h5 style={{ textAlign: "left", fontSize: "16px", fontWeight: "600", marginBottom: "10px" }}>
        Location: <span style={{ fontSize: "12px", color: "#555" }}>{name}</span>
      </h5>

      <div style={{ marginBottom: "15px" }}>
        <div style={{ fontWeight: "bold", marginBottom: "5px" }}>Information</div>
        {loading ? (
          "Loading...."
        ) : (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    padding: "8px",
                    borderBottom: "1px solid #ddd",
                    fontSize: "14px",
                  }}
                >
                  Category
                </th>
                <th
                  style={{
                    textAlign: "left",
                    padding: "8px",
                    borderBottom: "1px solid #ddd",
                    fontSize: "14px",
                  }}
                >
                  Count
                </th>
              </tr>
            </thead>
            <tbody>
              {information.length > 0 &&
                information.reduce((rows, item, index) => {
                  if (index % 2 === 0) {
                    rows.push([item]);
                  } else {
                    rows[rows.length - 1].push(item);
                  }
                  return rows;
                }, []).map((pair, rowIndex) => (
                  <tr key={rowIndex}>
                    {pair.map((item, colIndex) => (
                      <React.Fragment key={colIndex}>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={(e) => handleCategoryCheckbox(e, item.category)}
                            checked={isCategory[item.category] || false}
                            className="m-1 mt-2"
                          />
                          <span style={{ fontWeight: "bold", fontSize: "14px" }}>{item.category}</span>
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>{item.count}</span>
                        </td>
                      </React.Fragment>
                    ))}
                    {pair.length === 1 && (
                      <>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                          }}
                        ></td>
                        <td
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                          }}
                        ></td>
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>

      <div>
        <p style={{ fontWeight: "bold" }}>Filters</p>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            transform: "translateY(-10px)",
          }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>HV Network</span>
              </td>
              <td
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <input
                  type="checkbox"
                  onChange={(e) => handleLineCheckbox(e)}
                  checked={isLineCheck || false}
                  className="m-1 mt-2"
                />
              </td>
            </tr>

            <tr>
              <td
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>Line Color</span>
              </td>
              <td
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                <input
                  type="color"
                  id="colorPicker"
                  name="colorPicker"
                  value={lineColor}
                  onChange={handleColorChange}
                  className="rounded-circle"
                  style={{
                    backgroundColor: lineColor,
                    width: "20px",
                    height: "20px",
                    transform: "translateY(2px)",
                  }}
                />
              </td>
            </tr>

            <tr>
              <td className="" colSpan={2}>
                <button
                  className={`btn btn-outline-primary m-1 mx-1 ${isApplied ? "disabled" : ""}`}
                  style={{ float: "right", width: "100%" }}
                  onClick={applyFilter}
                >
                  {!isApplied ? "Apply" : "Applied"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const MarkerCluster = ({ data, renderPopup, flyname, setIsshowIds, isshowssIds, color, setColor }) => {
  const map = useMap();
  const [isChecked, setIsChecked] = useState({});

  useEffect(() => {
    if (!map || !data?.features?.length) return;

    // Initialize a marker cluster group
    const markers = L.markerClusterGroup({
      chunkedLoading: true, // For smoother performance on large datasets
      animate: true, // Enable animation
      animateAddingMarkers: true, // Enable animation for adding markers
      spiderfyOnMaxZoom: false, // Disable spiderfying at max zoom
    });

    let flyToMarker = null; // Variable to hold the marker to fly to if `flyname` is provided

    // Add GeoJSON points to the cluster group
    data.features.forEach((feature) => {
      const [lng, lat] = feature.geometry.coordinates;

      // Ensure the 'name' is always on top
      const name = feature.properties.name;
      const substationId = feature.properties.substation_id;
      // console.log("SubstationID", feature.properties);

      // Create the marker
      const marker = L.marker([lat, lng]);

      // Bind the tooltip to show the name
      marker.bindTooltip(name, {
        permanent: true, // Ensures the label is always visible
        direction: "top", // Position the label above the marker
        className: "custom-label", // Optional: Add custom styles
        offset: [-15, 0], // Adjust position of the label
      });

      const popupContainer = document.createElement("div"); // Create a DOM element for the popup content

      // Use React to render your component inside the popup container
      ReactDOM.createRoot(popupContainer).render(<PopUpContainer name={name} setIsshowIds={setIsshowIds} substationId={substationId} isshowssIds={isshowssIds} color={color} setColor={setColor} isChecked={isChecked} setIsChecked={setIsChecked} />);

      marker.bindPopup(popupContainer, {
        autoClose: false, // Keep the popup open until manually closed
        direction: "bottom",
        offset: [0, 10],
        keepInView: true, // Prevents the popup from being cut off at the map edge
      });

      // Listen for the popup open event to hide the tooltip
      marker.on("popupopen", () => {
        marker.unbindTooltip(); // Hide the tooltip when the popup is open
      });

      // Listen for the popup close event to show the tooltip again
      marker.on("popupclose", () => {
        marker.bindTooltip(name, {
          permanent: true, // Ensures the label is always visible again
          direction: "top", // Position the label above the marker
          className: "custom-label-close", // Optional: Add custom styles
          offset: [-15, 0], // Adjust position of the label
        });
      });

      // Check if this is the marker to fly to
      if (flyname && name === flyname) {
        flyToMarker = marker;
      }

      // Add the marker to the cluster group
      markers.addLayer(marker);
    });

    // Add the cluster group to the map
    map.addLayer(markers);

    // Fly to the specified marker if flyname is provided
    if (flyToMarker) {
      map.flyTo(flyToMarker.getLatLng(), 15, {
        duration: 1.5, // Duration of the zooming animation
        easeLinearity: 0.25, // Easing function for smooth transition
      });

      // Optionally, open the popup after flying
      flyToMarker.openPopup(); 
    }

    // Smooth zoom on cluster click
    markers.on("clusterclick", (event) => {
      const bounds = event.layer.getBounds();
      map.flyToBounds(bounds, {
        padding: [50, 50], // Padding around the bounds
        duration: 1.5, // Animation duration in seconds
        easeLinearity: 0.25, // Easing function for a smooth transition
      });
    });

    // Cleanup function to remove the cluster group from the map
    return () => {
      map.removeLayer(markers);
    };
  }, [map, data, renderPopup, flyname]);

  return null;
};

MarkerCluster.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        geometry: PropTypes.shape({
          type: PropTypes.string.isRequired,
          coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
        }).isRequired,
        properties: PropTypes.object.isRequired,
      })
    ).isRequired,
  }).isRequired,
  renderPopup: PropTypes.func, // Optional custom popup renderer
  flyname: PropTypes.string, // Optional fly to marker by name
};

MarkerCluster.defaultProps = {
  renderPopup: null,
  flyname: null,
};

export default MarkerCluster;
