import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import "../css/Sidebar.css";
import axios from "axios";
import L from 'leaflet';

const baseUrl = "https://geoserver.fornaxenergytech.com";

const RightSideDrawBar = ({
    // activeBaseLayer,
    // isDistrictVisble,
    isTalukaVisible,
    setGeojsonData,
    setGeojsonTalukaData,
    // setVillages,
    // handleBaseLayerChange,
    // handleMeasureChange,
    measureMode,
    setMeasureMode,
    handleMeasureChange,
    setIsTalukaVisible,
    // setIsDistrictVisible,
    selectedData,
    measureResult,
    setSelectedDistrictId,
    selectedDistrictId,
    subMenuSection,
    setSubMenuSection,
    baseLayer,
    setErrorMessage,
    measureDetail

}

) => {


  // console.log("Measure Result",measureResult);

  const [opendOption, setOpendOption] = useState("");
  const [isClassAdded, setIsClassAdded] = useState(false);

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    measurement: '',
    type: '',
  });

  useEffect(() => {
    if (measureDetail) {
      setFormData({
        id: measureDetail.id || '', // Ensure id is properly set or default to an empty string
        name: measureDetail.name || '', // Default to empty string if measureDetail.name is undefined
        measurement: measureDetail.measurement || '', // Default to empty string if measureDetail.measurement is undefined
        type: measureDetail.type || '', // Default to empty string if measureDetail.type is undefined
      });
    }
  }, [measureDetail]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };


  // useEffect(()=>{

    
  // if(isDistrictVisble == true){

  //   setOpendOption("District");
  //   setIsClassAdded(true);


  // }
  // else if(isTalukaVisible == true){
  //   setOpendOption("Taluka");
  //   setIsClassAdded(true);
  // }
  // else{
  //   setOpendOption();
  //   setIsClassAdded(false);
  //   setIsTalukaVisible(false);
  //   setIsDistrictVisible(false);

  // }


  // },[isDistrictVisble,isTalukaVisible])

  const openRightSubmenu = (tabName) => {
    const anchor = document.querySelector(".leaflet-popup-close-button");
    if (anchor) {
      anchor.click();
    }
    if (opendOption === tabName && isClassAdded === true) {
      setIsClassAdded(false);
      
    } else {
      setIsClassAdded((prevState) => !prevState);
    }
    setOpendOption(tabName);
    // if(opendOption == "draw"){
    //   setIsTalukaVisible(false);
    //   setIsDistrictVisible(false);
    // }
  };
  const rightSubmenuRef = useRef(null);
  const removeClass = () => {
    if (rightSubmenuRef.current) {
      setIsClassAdded(false);
      rightSubmenuRef.current.classList.remove('visible');
    }
  };



  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedTalukas, setSelectedTalukas] = useState([]);
  const [talukas, setTalukas] = useState([]);
  // const [villageShow, setVillageShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [layers, setLayers] = useState([]);
  const [layerVisibility, setLayerVisibility] = useState({});
  const [filterUpdated, setFilterUpdated] = useState(false); 

  

  const mapRef = useRef();

  const username = 'admin';
  const password = 'geoserver';

  

  const [districts, setDistricts] = useState([]);

  // console.log("check",selectedData);

  useEffect(() => {
    // if(baseLayer.includes("Districts")){
        axios
          .get(`${baseUrl}/geoserver/wfs`, {
            params: {
              service: 'WFS',
              version: '1.0.0',
              request: 'GetFeature',
              typeName: 'Gis_Gujarat:District',
              outputFormat: 'application/json'
            },
            auth: {
              username: username,
              password: password
            }
          })
        .then(response => {
          // console.log("DistrictList", response.data);
          const districtData = response.data.features.map(feature => ({
            id: feature.properties.id,
            name: feature.properties.district_n,
            geometry: feature.geometry
          }));
          setDistricts(districtData);
        })
        .catch(error => {
          console.error('Error fetching district data:', error);
        });
    // }
  }, []);
const[allTalukas,setAllTalukas] = useState([]);

  // useEffect(() => {
  //   // if(baseLayer.includes("Districts")){
  //       axios
  //         .get(`${baseUrl}/geoserver/wfs`, {
  //           params: {
  //             service: 'WFS',
  //             version: '1.0.0',
  //             request: 'GetFeature',
  //             typeName: 'Gis_Gujarat:taluka',
  //             outputFormat: 'application/json'
  //           },
  //           auth: {
  //             username: username,
  //             password: password
  //           }
  //         })
  //       .then(response => {
  //         const talukaData = response.data.features.map(feature => ({
  //           id: feature.properties.id,
  //           name: feature.properties.taluka_nam,
  //           districtId:feature.properties.district_i,
  //           geometry: feature.geometry
  //         }));

  //         console.log("Taluka", talukaData);

  //         setAllTalukas(talukaData);
  //         console.log("Fetch all Taluka",talukaData);
  //       })
  //       .catch(error => {
  //         console.error('Error fetching district data:', error);
  //       });
  //   // }
  // }, []);

 

 

  const[isForm,setIsForm] = useState(false);




  const handleDistrictChange = (districtId) => {
    setIsTalukaVisible(true);
    setGeojsonData('');
    setGeojsonTalukaData(null);
    // setSelectedTalukas('');
    // setSelectedDistricts('');
    setSelectedDistrictId(districtId);


    
    // const talukaData  = allTalukas.filter(taluka => taluka.district_id == districtId);

    // const talukaData = allTalukas.filter(taluka => taluka.district_id === districtId);

    // console.log("Filter Taluka", talukaData);

   
  };

// console.log("OutSide Selected District Data ID -> : ",selectedDistrictId);
useEffect(()=>{

  const selectedDistrictData = districts.find(district => district.id === selectedDistrictId);


  if (selectedDistrictData) {
    setSelectedDistricts(selectedDistrictData);

    const districtFilters = selectedDistrictData.id;

    axios
      .get(`${baseUrl}/geoserver/wfs`, {
        params: {
          service: 'WFS',
          version: '1.0.0',
          request: 'GetFeature',
          typeName: 'Gis_Gujarat:taluka',
          outputFormat: 'application/json',
          CQL_FILTER: `district_i='${districtFilters}'`
        },
        auth: {
          username: username,
          password: password
        }
      })
      .then(response => {
        // console.log("Taluka reponse",response.data)
        const talukaData = response.data.features.map(feature => ({
          id: feature.properties.id,
          name: feature.properties.taluka_nam,
          districtId: feature.properties.district_i,
          geometry: feature.geometry
        }));

        // console.log("Taluka reponse",talukaData)
        setTalukas(talukaData);

        const geojsonFeatures = [];

        if (selectedDistrictData.geometry && selectedDistrictData.geometry.type === 'MultiPolygon') {
          geojsonFeatures.push({
            type: 'Feature',
            properties: {
              id: selectedDistrictData.id,
              name: selectedDistrictData.name,
              type: 'district'
            },
            geometry: selectedDistrictData.geometry
          });
        }

        const geojson = {
          type: 'FeatureCollection',
          features: geojsonFeatures
        };

        setGeojsonData(geojson);
        setFilterUpdated(true);
      })
      .catch(error => {
        console.error('Error fetching taluka data:', error);
      });
  }

},[selectedDistrictId])

const[selectedTalukaId,setSelectedTalukaId] = useState();

  const handleTalukaChange = useCallback((talukaId) => {
   
    setGeojsonTalukaData(null);
    setIsVisible(true);
    setSelectedTalukaId(talukaId);
    const selectedTalukaData = talukas.find(taluka => taluka.id === talukaId);

    if (selectedTalukaData) {
      setSelectedTalukas(selectedTalukaData);

      const geojsonFeatures = [];

      if (selectedTalukaData.geometry && selectedTalukaData.geometry.type === 'MultiPolygon') {
        geojsonFeatures.push({
          type: 'Feature',
          properties: {
            id: selectedTalukaData.id,
            name: selectedTalukaData.name,
            type: 'Taluka'
          },
          geometry: selectedTalukaData.geometry
        });
      }

      const geojson = {
        type: 'FeatureCollection',
        features: geojsonFeatures
      };

      if (mapRef.current && isVisible) {
        const bounds = L.geoJSON(geojson).getBounds();

        if (bounds) {
          mapRef.current.fitBounds(bounds);
        }
      }

      setGeojsonTalukaData(geojson);
      setFilterUpdated(true);
    }
  }, [talukas, mapRef, isVisible]);




  // useEffect(() => {
  //   // Filter talukas based on districtId
  //   console.log("selectedDistrictId",selectedDistrictId);
  //   if(selectedDistrictId){

  //     const filteredTalukas = allTalukas.filter(taluka => taluka.districtId === selectedDistrictId);
  //     setTalukas(filteredTalukas);

  //   console.log("selected tALKAS",filteredTalukas);

    
  //   }


  //   }, [selectedDistrictId]);


  // const [groupedTalukas, setGroupedTalukas] = useState({});

  // useEffect(() => {
  //   const grouped = districts.reduce((acc, district) => {
  //     const talukasForDistrict = talukas.filter(taluka => taluka.district_id === district.id);

  //     console.log("talukasForDistrict", talukasForDistrict);

  //     if (talukasForDistrict.length > 0) {
  //       acc[district.id] = {
  //         district: district,
  //         talukas: talukasForDistrict,
  //       };
  //     }
  //     return acc;
  //   }, {});

  //   setGroupedTalukas(grouped);
  // }, []);

const[groupedTalukas, setGroupedTalukas] = useState();
useEffect(() => {
  const groupedTalukas = districts.reduce((acc, district) => {
    const talukasForDistrict = talukas.filter(taluka => taluka.districtId === district.id);
    if (talukasForDistrict.length > 0) {
      acc[district.id] = {
        district: district,
        talukas: talukasForDistrict
      };
    }
    return acc;
  }, {});
  
  setGroupedTalukas(groupedTalukas);
}, [districts, talukas]);
  // setGroupedTalukaData(groupedTalukas);


  useEffect(() =>{

    if(baseLayer.length > 0){
      setIsClassAdded(true);
    }
    else{
      setIsClassAdded(false);
    }
  },[baseLayer])



// console.log("Districts Data",districts);


useEffect(()=>{

  // console.error("submenu ",subMenuSection, " class Added" + isClassAdded);
  if(subMenuSection == "objects" && isClassAdded == false){
    setIsClassAdded(false);
// console.log("use Effect is nworking ");
  }
  else if(subMenuSection= "base-Layer"){
     setSelectedDistrictId(null);
     
  }
},[subMenuSection])

// console.log("Base Layer Information :-> ",baseLayer)
// console.log("Group Layer Information :-> ",groupedTalukas)

// console.log("group Taluka  Data",groupedTalukas);


  return (
    <>
      <div className="rightsidebar">

      {isClassAdded
         ? (
          <div
          ref={rightSubmenuRef}
            className={`sub-sidebar-wrap sub-menu ${
              isClassAdded ?  "visible" : ""
            }`}
          >
                  {subMenuSection != "objects" ? ( <>
            <ul
              data-parent="map-tool-bar"
              className={`${baseLayer.includes("Districts") ? "d-block" : "d-none"}`}
            >
              <li>
                <h6 className="sub-menu-title mb-0">{baseLayer.includes("Districts") ? "Districts" : ""}</h6>
              </li>

              {baseLayer.includes("Districts") && districts.map((district) => (
              <li key={district.id} className="formcheck">
                <a
                  key={district.id}
                  href="#"
                  id={`district_${district.id}`}
                  value={district.id}
                  onClick={() => handleDistrictChange(district.id)}
                  className={`${selectedDistrictId === district.id ? "active" : ""}`}
                >
                  {district.name}
                </a>
              </li>
            )) }



              {/* <li>
                <a href="#" onClick={() => handleTracing("length")}>
                  Length
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#cccecf" }}>
                  Area
                </a>
              </li> */}
            </ul>
       
    
<ul
  data-parent="map-tool-bar"
  className={`${baseLayer.includes("Talukas") ? "d-block" : "d-none"}`}
>
  {baseLayer.includes("Talukas") && Object.values(groupedTalukas).map((group) => (
    <li key={group.district.id}>
      <h6 className="sub-menu-title mb-0">{group.district.name}<span style={{ fontSize: '0.5em' }}>&nbsp; Taluka</span></h6>
      {group.talukas.map((taluka) => (
        <li key={taluka.id} className="formcheck">
          <a
            className={`${selectedTalukaId === taluka.id ? "active" : ""}`}
            id={`taluka_${taluka.id}`}
            value={taluka.id}
            onClick={() => handleTalukaChange(taluka.id)}
          >
            {taluka.name}
          </a>
        </li>
      ))}
    </li>
  ))}
</ul>
</>
) : <><h1>Please Select Districts</h1></> }


{ subMenuSection == "objects" ? (
  <>
<ul
              data-parent="map-tool-bar"
              className={`${opendOption == "draw"  ? "d-block" : "d-none"}`}
            >
              <li>
                <h6 className="sub-menu-title mb-0">Draw</h6>
              </li>
              <li>
          <a
            href="#"
            className={measureMode === 'area' ? 'active' : ''}
            onClick={() => handleMeasureChange('area')}
          >
            Measure Area
          </a>
        </li>
        <li>
          <a
            href="#"
            className={measureMode === 'length' ? 'active' : ''}
            onClick={() => handleMeasureChange('length')}
          >
            Measure Length
          </a>
        </li>
        <li>
          <a
            href="#"
            className={measureMode === 'point' ? 'active' : ''}
            onClick={() => handleMeasureChange('point')}
          >
            Measure Point
          </a>
        </li>

        <li>
          <a
            href="#"
            className={measureMode === 'circle' ? 'active' : ''}
            onClick={() => handleMeasureChange('circle')}
          >
            Measure Circle
          </a>
        </li>

              {/* {isDistrictVisble && isTalukaVisible == false && districts.map((district) => (
              <li key={district.id} className="formcheck">
                <a
                  key={district.id}
                  href="#"
                  id={`district_${district.id}`}
                  value={district.id}
                  onClick={() => handleDistrictChange(district.id)}
                  className={`${selectedDistrictId === district.id ? "active" : ""}`}
                >
                  {district.name}
                </a>
              </li> */}
            {/* ))  */}
            {/* } */}

            {measureDetail && (
 <>
<li className="mt-5">
  <h6 className="sub-menu-title mb-0 pt-5 d-flex justify-content-between">
    <span>{measureDetail.name}</span>
    <img src="plusSVG.svg" onClick={() => setIsForm(true)} alt="plus icon" />
    <span>{measureDetail.measurement } {measureDetail.type == "area" || measureDetail.type == "circle" ? " km²" : "km"}</span>
  </h6>
  {/* <h7 className="mb-0 text-center">Measurement: &nbsp;{measureResult}</h7> */}
</li>

<li className={`propertyform ${isForm ? "open" : ""}`}>
  {/* Content of the form goes here */}
  {/* <h1>Form</h1> */}

  
  <div className="col-md-12">
        <div className="mb-3">
          <label className="form-label1" htmlFor="name">
            Id
          </label>
          <input
            placeholder="Enter Id"
            type="text"
            id="name"
            className="form-control form-control-sm"
            value={formData.id}
            onChange={handleChange}
          />
        </div>
      </div>

  <div className="col-md-12">
        <div className="mb-3">
          <label className="form-label1" htmlFor="name">
            Name
          </label>
          <input
            placeholder="Enter Name"
            type="text"
            id="name"
            className="form-control form-control-sm"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="mb-3">
          <label className="form-label1" htmlFor="type">
           Area Type
          </label>
          <select
            className="form-select form-select-sm"
            id="type"
            value={formData.type}
            onChange={handleChange}
          >
            <option value="">Select Type</option>
            <option value="area">Area</option>
            <option value="length">Length</option>
            <option value="point">Point</option>
            <option value="circle">Circle</option>
          </select>
        </div>
      </div>

      {/* <div className="col-md-12">
        <div className="mb-3">
          <label className="form-label1" htmlFor="measurement">
            Measurement
          </label>
          <input
            placeholder="Enter Measurement"
            type="text"
            id="measurement"
            className="form-control form-control-sm"
            value={formData.measurement}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="col-md-12">
        <div className="mb-3">
          <label className="form-label1" htmlFor="station">
            Station
          </label>
          <select
            className="form-select form-select-sm"
            id="station"
            value={formData.station}
            onChange={handleChange}
          >
            <option value="">Select Station</option>
            <option value="subStation">Sub Station</option>
            <option value="subDivision">Sub Division</option>
          </select>
        </div> */}
      {/* </div> */}
      <div className="d-flex justify-content-between">
      <button
                      style={{
                        padding: "8px 30px",
                        backgroundColor: "blue",
                        borderRadius: "8px",
                        color: "white",
                        fontSize: "12px",
                        marginTop: "20px",
                        width:'200px'
                      }}
                      // onClick={handleEditClickSave}
                    >
                      Save
                    </button>
                    <button
                      style={{
                        padding: "6px 20px",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        color: "black",
                        fontSize: "12px",
                        marginTop: "20px",
                        marginLeft: "10px",
                        border: "1px solid black",
                        width:'200px'
                      }}
                      onClick={() =>{ setIsForm(false)}}
                    >
                      Cancle
                    </button>
                    </div>
</li>


   </>
)} 


            </ul>

            </>
        ) : null}

            <button className="close-submenu" onClick={removeClass}>
            <img src="right-double-chevron.png" alt="" />
          </button>






</div>
        ) : null}

{/* 
        <div className="main-menu">
          <ul className="rightsidebar-menu">
          {/* <li>
              <a  onClick={() => openRightSubmenu("")} title="Map Tool">
                <img src="Map-Tool.svg" className="sidebar-icon" alt="map_tool" />
              </a>
            </li> */}
            {/* <li>
              <a onClick={() => openRightSubmenu("map_tool")} title="Map Tool">
                <img src="Map-Tool.svg" className="sidebar-icon" alt="map_tool" />
              </a>
            </li> */}
            {/* { subMenuSection == "objects" ?(
              <>
            <li>
              <a onClick={() => openRightSubmenu("draw")} title="Draw">
                <img src="Draw.svg" className="sidebar-icon" alt="draw" />
              </a>
            </li>
            <li>
              <a href="#">
                <img src="Style.svg" className="sidebar-icon" alt="" />
                <span>Style</span>
              </a>
            </li>

            </>

) :  (<li>
<a onClick={() => openRightSubmenu("")} title="Draw">
  <img src="backSVG.svg" className="sidebar-icon" alt="draw" />
</a>
</li> )}

          

          </ul>
       
        </div> */} 
      </div>
     
    </>
  );
};

export default RightSideDrawBar;
