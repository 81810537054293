import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import L from "leaflet";
import {
  MapContainer,
  LayersControl,
  TileLayer,
  // Marker,
  Polyline,
  GeoJSON,
  // Circle,
  useMap,
  // Tooltip,
  useMapEvents,
  ZoomControl,
  WMSTileLayer
} from "react-leaflet";
import * as turf from "@turf/turf";
import { BingLayer } from "react-leaflet-bing-v2";
import "leaflet/dist/leaflet.css";
import "./css/map_view.css";
import PointData from "../assets/sec_point";
import Linedata from "../assets/sec_line";
// import RightSidebar from "./RightSidebar";
import LeftSidebar from "./LeftSidebar";
import TopNavbar from "./TopNavbar";
import {

  pointsOnLine,
  convertClassNameString,
} from "../utils/map";

// Import custom marker icon images
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";
// import RightSideBaseLayer from './BaseLayer/RightSideBaseLayer';
import RightSideDrawBar from "./BaseLayer/RightSideDrawBar";
// import { FaMapMarkerAlt } from 'react-icons/fa';
// import { renderToStaticMarkup } from 'react-dom/server';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-easyprint/dist/bundle';

// import L from 'leaflet';
import 'leaflet-draw';
// import 'leaflet-geometryutil';
//import jsPDF from "jspdf";
import 'leaflet-geometryutil';
import MapEvents from './MapEvents'
// import PrintControl from "./PrintControl";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import domtoimage from 'dom-to-image';
// import { BiPolygon } from "react-icons/bi";
// import { IoRemoveOutline } from "react-icons/io5";

import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';

// import { debounce } from 'lodash';
import MarkerCluster from "./functions/MarkerClustur";
// import { fetchLineData } from "./functions/fetchLineData";
import { exportMapAsPDF } from "./functions/exportMapAsPDF";
// import { exportAllLayersAsPDF } from "./functions/exportAllLayersAsPDF";
import { fetchObjectData } from "./functions/fetchObjectData";
// import LineDataLayer from "./functions/LineDataLayer";
import Loader from "./loading/Loader";
// import { MarkerClusterGroup } from "leaflet";


const triangleSVG = `
   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="lightblue" stroke="blue" stroke-width="2">
    <polygon points="12,2 22,22 2,22" />
  </svg>
`;

// const basicIcon = L.divIcon({
//   html: triangleSVG,
//   className: 'custom-icon',
//   iconSize: [24, 24],
//   iconAnchor: [12, 24],
// });

const ZoomTracker = ({ setZoom }) => {
  // Hook into the map's events using useMapEvents
  useMapEvents({
    zoomend: (event) => {
      const zoomLevel = event.target.getZoom(); // Get the current zoom level
      setZoom(zoomLevel); // Update the zoom level in state
    },
  });

  return null; // This component doesn't render anything itself
};

/*import Markers from './VenueMarkers';*/
const MapComponent = ({ authDetails}) => {
  //state = {};
  const { BaseLayer } = LayersControl;
  const mapRef = useRef(null);
  const geoJSONBetweenTraceRef = useRef(null);
  const [activeLayer, setActiveLayer] = useState("Canvas");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState([]);
  // const [featureProperties, setFeatureProperties] = useState(null);
  const [zoom, setZoom] = useState(5);
  const [datapoint, setDatapoint] = useState(PointData);
  const [dataLine, setDataLine] = useState(Linedata);
  const [pointLayer, setPointLayer] = useState(null);
  const [lineLayer, setLineLayer] = useState(null);
  const [center, setCenter] = useState([
    PointData.features[0].geometry.coordinates[1],
    PointData.features[0].geometry.coordinates[0],
  ]);
  const [className, setClassName] = useState("container-hide");
  const [poleClassName, setPoleClassName] = useState("pole-hide");
  // const [pointDataElements, setPointDataElements] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [traceBetween, setTraceBetween] = useState([]);
  // const [openedRightMenuOption, setOpenedRightMenuOption] = useState(null);
  const [circles, setCircles] = useState([]);
  // const [networkDetails, setNNetworkDetails] = useState({
  //   pathLength: 0,
  //   spanCount: 0,
  //   Transformer: 0,
  //   Switch: 0,
  //   Capacity: 0,
  // });
  const [isMeasuring, setIsMeasuring] = useState(false);

  const [points, setPoints] = useState([]);
  const [distances, setDistances] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  // const [getidInRighSidebar, setGetIdInRightsidebar] = useState(null);

  // const [cursorPosition, setCursorPosition] = useState(null);
  // const [measuringPoint, setMeasuringPoint] = useState(null); 

  const [lineColor, setLineColor] = useState("#0080ff");
  // const [uniquePointTypes, setUniquePointTypes] = useState([]);
  const [selectedData, setSelectedData] = useState(null); // State for selected layer detailed data
  // const [filteredData, setFilteredData] = useState([]);
  const[measureDetail,setMeasureDetail] = useState();

  const[isGujaratBoundary,setIsGujaratBoundary] = useState(true);
  const[loading, setLoading] = useState(false);

  
const gujaratBounds = [
  [19.1226, 67.1629], // Southwest coordinates (bottom-left)
  [25.3963  -0.50, 75.4769 + 0], // Adjusted northeast coordinates
];
  
  const [visibleElements, setVisibleElements] = useState({
    gentry: true,
    ht_pole: true,
    transformer: true,
    rmu: true,
    rmu_with_tc: true,
    switch: true,
    fuse: true,
  });
  const [selectedOptions, setSelectedOptions] = useState({
    organization: null,
    network: null,
    hvNetwork: null,
    selectedProjects: [],
    drawnLines: [], // Example: Array to store drawn lines data
  });

  // const[activeBaseLayer,setActiveBaseLayer] = useState();
  const[selectedTaluka,setSelectedTaluka] = useState();
  // const[isDistrictVisble,setIsDistrictVisible] = useState(false);
  const[isTalukaVisible, setIsTalukaVisible] = useState(false); 
  const [geojsonData, setGeojsonData] = useState(null);
  const[geojsonTalukaData,setGeojsonTalukaData] = useState();
  // const[villages,setVillages] = useState();
  const[zindex, setZindex] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const[isMapVisble,setIsMapVisible] = useState(true);
  const [measureResult, setMeasureResult] = useState(null); // Result of measurement
  const [drawnLayers, setDrawnLayers] = useState([]);
  const[measurementVisible,setMeasurementVisible] = useState(false);
  const [pointGeometry, setPointGeometry] = useState(null);
  // const[baselayerVisble,setBaselayerVisble] = useState();
  
  const [selectedDistrictId, setSelectedDistrictId] = useState();
 
  const [measureMode, setMeasureMode] = useState('');

  const[districtStyle, setDistrictStyle] = useState({color:'#0B7D7D', opacity:1,fillColor: '#008000', fillOpacity:'0.3',zIndex:1200});
  const[talukaStyle, setTalukaStyle] = useState({color:'#000000', opacity:1,fillColor: '#0000FF', fillOpacity:'0.2', zIndex:1250});
  const[companyStyle, setCompanyStyle] = useState({color:'#000000', opacity:0.7, fillColor: '#000000', fillOpacity:'0.3', zIndex:1000});
  const[highwayStyle, setHighwayStyle] = useState({color:'#6B6B6B', opacity:2});

  const[circleStyle,setCircleStyle] = useState({color:'#660f0f', opacity:1,fillColor: '#8B0000', fillOpacity:'0.2', zIndex:1001});
  const[divisionStyle,setDivisionStyle] = useState({color:'#402e03', opacity:1,fillColor: '#B8860B', fillOpacity:'0.2', zIndex:1002});
  const[subDivisionStyle,setSubDivisionStyle] = useState({color:'#000000', opacity:1,fillColor: '#e71708', fillOpacity:'0.2', zIndex:1003});
  const [searchData, setSearchData] = useState({ project_id: '', category: ''});
  const [color, setColor] = useState({});
  // const [organization, setOrganization] = useState([]);
const[clearnetwork,setClearnetwork] = useState(false);

const[waterStyle,setwaterStyle] = useState({color:'#1201F9', opacity:0.7,fillColor: '#B8EBF5', fillOpacity:'0.2', zIndex:1001});
const[railStyle,setRailStyle] = useState({color:'#747c8a', opacity:2});
const[reserveStyle,setReserveStyle]= useState({color:'#40A609', opacity:0.8,fillColor: '#95D672', fillOpacity:'0.2', zIndex:1001});

const[waterLayerData,setWaterLayerData] = useState();
const[railwayLayerData,setRailwayLayerData] = useState();
const[reserverLayerData,setReserverLayerData] = useState();

const[transformerCheckedIds, setTransformerCheckedIds] = useState([]);
// const [subStationGeojson, setSubStationGeojson] = useState([]);
const [subStationGeojson, setSubStationGeojson] = useState({
  type: 'FeatureCollection',
  features: [],
});
const [subStatation, setSubStatation] = useState([]);


// const map = useMap();

  // const[highwayStyle,setHighwayStyle] = useState({color:"#00000", opacity:1});
  const handleClearNetwork = () => {
    setOrganization([]);
    setCircleData(null);
    setSubDivisionData(null);
    setDivisionData(null);
    setCircleGeojson(null);
    setCompanyGeojson(null);
    setDivisionGeojson(null);
    setSubDivisionData(null);
    setCircles([]);
    // setEHVLinegeojson(false);
    setSubDivisiongeojson(null);
    setTraceBetween([]);
    setMarkers([]); // Set markers to an empty array
    // setVisibleElements([]);
    setDatapoint([]);
    setDataLine([]);
    // console.log("Clear All Network");

  };
  

  const handleMeasureChange = (mode) => {

    if(measureMode === mode){
    setMeasureMode('');

    }else{

      setMeasureMode(mode);
      setMeasurementVisible(true);
  
    }

  };


  const [baseLayer, setBaseLayer] = useState([]);

 

  const handleBaseLayerChange = (data) => {

    if(baseLayer == data){
      setBaseLayer([]);

    }else{
    setBaseLayer([data]);

    }

    if(data == "Talukas"  && !baseLayer.includes("Talukas") && selectedDistrictId == null){
      setIsTalukaVisible(false);
      setErrorMessage("Please Select District");
      setBaseLayer([]);

    }else{
      setErrorMessage("");
      

    }
  };
  

  const  handleClearLineNetwork = () =>{
    setDatapoint([]);
    setDataLine([]);
    setLineDataGeoJSON([])
    // setEHVLinegeojson(null);
    // setObjectgeojson(null);
    setSubStationGeojson([]);
    setIsshowIds([]);
    // setEHVLinegeojson(false);
    // setSubDivisiongeojson(null);
    setTraceBetween([]);
    setMarkers([]); // Set markers to an empty array

    setClearnetwork(!clearnetwork);
    console.warn("handleClearLineNetwork");
  }

  const handleRefresh = () => {
    // setActiveBaseLayer('');
    setSelectedTaluka('');
    // setIsDistrictVisible(false);
    setIsTalukaVisible(false);
    setGeojsonTalukaData(null);
    setGeojsonData(null);
    setSelectedDistrictId(null);
  
  }

  

  const newCalculateDistance = (latlng1, latlng2) => {
    const from = L.latLng(latlng1);
    const to = L.latLng(latlng2);
    return from.distanceTo(to) / 1000; // Distance in kilometers
  };
  // const MyComponent = () => {
  //   useMapEvents({
  //     mousemove: (e) => {
  //       setCursorPosition(e.latlng);
  //     },
  //     dblclick: (e) => {
  //       handleDoubleClick(e);
  //     },
  //     click: (e) => {
  //       const newPoints = [...points, e.latlng];
  //       setPoints(newPoints);
  //       setMeasuringPoint(e.latlng);
  //       if (newPoints.length > 1) {
  //         const lastPoint = newPoints[newPoints.length - 2];
  //         const newDistance = newCalculateDistance(lastPoint, e.latlng);
  //         //const distance = calculateDistance(e.latlng, cursorPosition);
  //         const newTotalDistance = totalDistance + newDistance; // + distance;

  //         setDistances([...distances, newDistance]);
  //         setTotalDistance(newTotalDistance);
  //       }
  //     },
  //   });

  //   return null;
  // };


  // const handleDoubleClick = (e) => {
  //   setIsMeasuring(false);
  // };

  const clearLengthMapping = () => {
    // setCursorPosition(null); // Clear cursor position when turning off measuring
    setPoints([]);
    setDistances([]);
    setTotalDistance(0);
  };

  // const handleToggleMeasuring = () => {
  //   setIsMeasuring(!isMeasuring);
  //   if (isMeasuring) {
  //     clearLengthMapping();
  //   }
  // };

  // // Create custom icon instance
  // const customIcon = new L.Icon({
  //   iconUrl: markerIcon,
  //   shadowUrl: markerIconShadow,
  //   iconSize: [25, 41], // Size of the icon
  //   iconAnchor: [12, 41], // Point of the icon which will correspond to marker's location
  //   shadowSize: [41, 41], // Size of the shadow
  //   shadowAnchor: [12, 41], // Point of the shadow which will correspond to marker's location
  //   popupAnchor: [1, -34], // Point from which the popup should open relative to the iconAnchor
  // });



  const handleDataFromChild = (newdata, dataline) => {
    setDatapoint(newdata);
    setDataLine(dataline);


  };


  useEffect(() => {
    // Update map center when center state changes
    if (mapRef.current) {
      mapRef.current.setView(center);
    }
  }, [center]);


  useEffect(() => {
    // if (isMeasuring) {
    //   setMeasuringPoint(
    //     L.latLng(
    //       selectedFeature.geometry.coordinates[1],
    //       selectedFeature.geometry.coordinates[0]
    //     )
    //   );
    // }
  }, [selectedFeature]);

  useEffect(() => {
    Object.keys(visibleElements).map((key) => {
      const elements = document.getElementsByClassName(
        `leaflet-marker-icon ${key}`
      );
      for (let i = 0; i < elements.length; i++) {
        if (visibleElements[key]) {
          elements[i].classList.remove("d-none");
        } else {
          elements[i].classList.add("d-none");
        }
      }
    });
  }, [visibleElements]);

  useEffect(() => {
    // Update map center when center state changes
    const anchor = document.querySelector(".leaflet-popup-close-button");
    if (anchor) {
      anchor.click();
    }

    if (traceBetween.length > 1) {
      var features = traceBetween;
      const point1 = traceToTopParent(features["0"].properties.id);
      const point2 = traceToTopParent(features["1"].properties.id);
      getNonCommonElements(point1, point2);
    }
  }, [traceBetween]);

  const getNonCommonElements = (json1, json2) => {
    var result = [];
    json1.map((item) => {
      var id = item.id;
      if (json2.some((item) => item.id === id)) {
        //result.push(item);
      } else {
        result.push(item);
      }
    });

    json2.map((item) => {
      var id = item.id;
      if (json1.some((item) => item.id === id)) {
        //result.push(item);
      } else {
        result.push(item);
      }
    });

    setSelectedFeatureId(result);
    const map = mapRef.current;
    geoJSONBetweenTraceRef.current = L.geoJSON(result, {
      style: function (feature) {
        var lineProps = JSON.parse(feature.line_props);
        if (lineProps.position === "underground") {
          return { dashArray: "8, 8", color: "green" };
        } else {
          return { color: "green" };
        }
      },
      //onEachFeature: onFeatureEachData
    }).addTo(map);

    calculateNetworkDetails(result);

    traceBetween.map((tracePoint, idx) => {
      highlightelement(
        [
          tracePoint.geometry.coordinates["1"],
          tracePoint.geometry.coordinates["0"],
        ],
        idx
      );
    });
    setTraceBetween([]);
    //return result;
  };

  const calculateCenter = (coords) => {
    if (coords.length === 0) return [0, 0];

    let totalLat = 0;
    let totalLng = 0;
    coords.forEach((coord) => {
      totalLat += parseFloat(coord[1]);
      totalLng += parseFloat(coord[0]);
    });

    const avgLat = totalLat / coords.length;
    const avgLng = totalLng / coords.length;

    setCenter([avgLat, avgLng]);
    return [avgLat, avgLng];
  };

  useEffect(() => {
    // Clear previous GeoJSON layers
    if (mapRef.current && pointLayer && lineLayer) {
      pointLayer.clearLayers();
      lineLayer.clearLayers();
    }

    // Add new GeoJSON layers
    if (mapRef.current) {
      const map = mapRef.current;

      // setPointDataElements(datapoint);
      // setBaselayerVisble(false);

      // Add GeoJSON point layer
      const pointLayer = L.geoJSON(datapoint, {
        onEachFeature: onEachData,
      }).addTo(map);
      setPointLayer(pointLayer);

      // Add GeoJSON line layer
      const lineLayer = L.geoJSON(dataLine, {
        style: function (feature) {
          var lineProps = JSON.parse(feature.line_props);
          if (lineProps.position === "underground") {
            return { dashArray: "8, 8" };
          }
        },
        onEachFeature: onFeatureEachData,
      }).addTo(map);

      setLineLayer(lineLayer);

      map.on("zoomend", function () {
        // console.error(map.getZoom);
        if (map.getZoom() > 15) {
          setClassName("container-show");
        } else {
          setClassName("container-hide");
        }
        if (map.getZoom() > 17) {
          setPoleClassName("pole-show");
        } else {
          setPoleClassName("pole-hide");
        }
      });

      const tracing = L.geoJSON(selectedFeatureId, {
        style: function (feature) {
          var lineProps = JSON.parse(feature.line_props);
          if (lineProps.position === "underground") {
            return { dashArray: "8, 8", color: "red" };
          } else {
            return { color: "red" };
          }
        },
        //onEachFeature: onFeatureEachData
      }).addTo(map);
    }
  }, [
    datapoint,
    dataLine,
    selectedFeatureId,
    className,
    poleClassName,
    lineColor,
  ]);

  // const [map, setMap] = useState(null); // Keep reference to the map instance
  // const [showGeoJson, setShowGeoJson] = useState(false); // State to toggle GeoJSON visibility




  var result = [];
  const buildNestedStructure = (items, parentId) => {
    items.features.map((item) => {
      if (item.properties.parent_id === parentId) {
        const children = buildNestedStructure(items, item.id);
        if (children.length) {
          item.children = children;
        }
        item.properties.color = "green";
        result.push(item);
      }
    });

    return result;
  };

  const traceToTopParent = (id, path = []) => {
    if (!id || !dataLine) {
      return path;
    }
    const feature = dataLine.features.find(
      (feature) => feature.properties.id === id
    );
    if (feature && feature.id) {
      feature.properties.color = "green";
      path.push(feature); // Add current feature to the path

      if (feature?.properties?.parent_id) {
        return traceToTopParent(feature.properties.parent_id, path); // Recursively trace to the parent
      }
    }
    return path;
  };

  const handleUpwardTrace = (featureId) => {
    const nestedData = traceToTopParent(featureId);
    setSelectedFeatureId(nestedData);
    const map = mapRef.current;
    const tracing = L.geoJSON(nestedData, {
      style: function (feature) {
        var lineProps = JSON.parse(feature.line_props);
        if (lineProps.position === "underground") {
          return { dashArray: "8, 8", color: "green" };
        } else {
          return { color: "green" };
        }
      },
      //onEachFeature: onFeatureEachData
    }).addTo(map);
    calculateNetworkDetails(nestedData);
  };

  const calculatePathLength = (line) => {
    try {
      const lineString = turf.lineString(line.geometry.coordinates);
      const length = turf.length(lineString, { units: "kilometers" });
      return length;
    } catch (error) {
      console.error("Error calculating path length:", error);
      return 0;
    }
  };

  const handleFeatureClick = (featureId) => {
    const nestedData = buildNestedStructure(dataLine, featureId);
    const selectedFeature = dataLine.features.find(
      (feature) => feature.properties.parent_id === featureId
    );

    if (selectedFeature) {
      setSelectedFeatureId(nestedData);
      const map = mapRef.current;
      const tracing = L.geoJSON(nestedData, {
        style: function (feature) {
          var lineProps = JSON.parse(feature.line_props);
          if (lineProps.position === "underground") {
            return { dashArray: "8, 8", color: "red" };
          } else {
            return { color: "red" };
          }
        },
        //onEachFeature: onFeatureEachData
      }).addTo(map);
      calculateNetworkDetails(nestedData);
    }
  };
// console.log(activeBaseLayer,"activeBaseLayer");
  const onEachData = (data, layer) => {
    // setGetIdInRightsidebar(data);
    let dataid = JSON.parse(data.properties.point_props); // data.properties.point_type +' - '+ data.id;
    const dataName = data.properties.point_type;

    let imgURL = "map_icons/ht_pole.svg"; // Default icon URL for HT pole
    switch (dataName) {
      case "Existing Pole(Double Circuit)":
        imgURL = "map_icons/existing_pole.svg";
        break;
      case "Fuse":
        imgURL = "map_icons/Fuse.svg";
        break;
      case "CTPT":
        imgURL = "map_icons/CTPT.svg";
        break;
      case "HT Pole":
        imgURL = "map_icons/ht_pole.svg";
        break;
      case "Switch":
        imgURL = "map_icons/Switch.svg";
        break;
      case "Gentry":
        imgURL = "map_icons/Gentry.svg";
        break;
      case "Transformer":
        imgURL = "map_icons/Transformer.svg";
        break;
      case "HT Route Point":
        imgURL = "map_icons/ht_route_point.svg";
        break;
      case "RMU":
        imgURL = "map_icons/RMU.svg";
        break;
      case "RMU with TC":
        imgURL = "map_icons/rmu_with_tc.svg";
        break;
    }

    const iconSize =
      dataName === "HT Pole" ||
      dataName === "Existing Pole(Double Circuit)" ||
      dataName === "HT Route Point"
        ? [10, 10]
        : [20, 20];
    const classText =
      dataName === "HT Pole" ||
      dataName === "Existing Pole(Double Circuit)" ||
      dataName === "HT Route Point"
        ? poleClassName
        : className;

    var tempName = classText + " " + convertClassNameString(dataName);

    // if (!visibleElements[convertClassNameString(dataName)]) {
    //   tempName = tempName + " d-none";
    // }

    const icon = L.icon({
      iconUrl: imgURL,
      iconSize: iconSize,
      className: tempName,
    });

    layer.setIcon(icon);

    layer
      .bindPopup(
        `
            <div class="leaflet-header">
                <h4>${dataName}</h4>
            </div>
            <div class="leaflet-body">
                <div class="modal-thumb">
                    <img src="${imgURL}" title="${dataName}" alt="${dataName}">
                </div>
                <div class="info-wrap">
                    <h6>Basic Details</h6>
                    <p class=""><span class="text-secondary">Feeder Name: </span><span class="word-break-all">${
                      data.properties.project_name
                    }</span></p>
                    <p class=""><span class="text-secondary">Element ID: </span><span class="word-break-all">${
                      data.properties.id
                    }</span></p>
                </div>
            </div>
            <div class="leaflet-footer">
                <div class="btn-group">
                    <button class="btn-custom" id="properties" type="button">Properties</button>
                    <button class="btn-custom" id="tracing" type="button">Tracing</button>
                    ${
                      dataName === "Gentry"
                        ? '<button class="btn-custom" id="network-check-button" type="button">Check Network</button>'
                        : ""
                    }
                    ${
                      dataName === "Transformer"
                        ? '<button class="btn-custom" id="view-sld" type="button">View SLD</button>'
                        : ""
                    }
                    <button class="btn-custom d-none" id="upward-tracing-button" type="button">Upward Tracing</button>
                    <button class="btn-custom d-none" id="downward-tracing-button" type="button">Downward Tracing</button>
                    <button class="btn-custom d-none" id="between-tracing-button" type="button">Between Tracing</button>
                </div> 
            </div>
        `
      )
      .on("popupopen", () => setupPopupEventHandlers(data))
      .on("popupclose", () => closePopupEventHandlers(data));

    layer.bindTooltip("Id: " + data.properties.id); // Adding tooltip
  };

  const closePopupEventHandlers = (data) => {
    //setOpenedRightMenuOption(null);
  };

  const calculateNetworkDetails = (pathData) => {
    var length = 0;
    pathData.map((tracePoint, idx) => {
      length = length + calculatePathLength(tracePoint);
    });

    var points = pointsOnLine(pathData, datapoint);
    var transformers = 0;
    var switchCount = 0;
    var totalXmrCapacity = 0;
    var Ids = [];
    if (points?.features && points.features.length) {
      points.features.map((point, idx) => {
        if (point.properties && !Ids.includes(point.properties.id)) {
          Ids.push(point.properties.id); // Add ID to array if it's not already included
          if (point.properties.point_type === "Transformer") transformers++;
          if (point.properties.point_type === "Switch") switchCount++;
          let point_props = JSON.parse(point.properties.point_props);
          totalXmrCapacity += Number(point_props.xmr_capacity ?? 0);
        }
      });
    }

    // setNNetworkDetails({
    //   pathLength: length.toFixed(2),
    //   spanCount: pathData.length,
    //   Transformer: transformers,
    //   Switch: switchCount,
    //   Capacity: totalXmrCapacity,
    // });
  };

  const setupPopupEventHandlers = (data) => {
    //setNetworkLength(null);
    setSelectedFeature(data);
    var featureId = data.properties.id;
    let result = JSON.parse(data.properties.point_props);
    result.point_name = data.properties.point_type;
    result.element_id = data.properties.id;
    // setFeatureProperties(result);

    // const viewSld = document.querySelector("#view-sld");
    // if (viewSld) {
    //   viewSld.onclick = () =>
    //     setOpenedRightMenuOption({ tab: "sld", time: String(new Date()) });
    // }

    const downwardButton = document.querySelector("#downward-tracing-button");
    if (downwardButton) {
      downwardButton.onclick = () => handleFeatureClick(featureId);
    }

    const upWardButton = document.querySelector("#upward-tracing-button");
    if (upWardButton) {
      upWardButton.onclick = () => handleUpwardTrace(featureId);
    }

    const networkCheck = document.querySelector("#network-check-button");
    if (networkCheck) {
      networkCheck.onclick = () => checkNetwork(data);
    }

    const betweenButton = document.querySelector("#between-tracing-button");
    if (betweenButton) {
      betweenButton.onclick = () => {
        if (traceBetween.length === null) {
          setTraceBetween(data);
        } else {
          setTraceBetween((prevArray) => [...prevArray, data]);
        }
        //const nestedData = traceToTopParent(featureId);
      };
    }

    // const propertiesBtn = document.querySelector("#properties");
    // if (propertiesBtn) {
      // propertiesBtn.onclick = () =>
      //   setOpenedRightMenuOption({
      //     tab: "properties",
      //     time: String(new Date()),
      //   });
    // }

    // const tracingBtn = document.querySelector("#tracing");
    // if (tracingBtn) {
    //   tracingBtn.onclick = () =>
    //     setOpenedRightMenuOption({ tab: "tracing", time: String(new Date()) });
    // }

    highlightelement([
      data.geometry.coordinates["1"],
      data.geometry.coordinates["0"],
    ]);

    if (geoJSONBetweenTraceRef.current !== null) {
      mapRef.current.removeLayer(geoJSONBetweenTraceRef.current);
      geoJSONBetweenTraceRef.current = null;
    }
  };

  const checkNetwork = (pointData) => {
    setLineColor("#000000");
    setTimeout(
      function () {
        setLineColor("#0080ff");
        alert("Network Connectivity OK!");
      }.bind(this),
      500
    );
  };

  const highlightelement = (latLag, isAdditional = 0) => {
    const newCircle = {
      latlng: latLag,
      radius: 10, // Set the desired radius here
      color: "red",
      fillColor: "#f03",
      fillOpacity: 0,
    };

    if (isAdditional > 0) {
      setCircles((prevCircles) => [...prevCircles, newCircle]);
    } else {
      setCircles([newCircle]);
    }
  };

  const handleTracing = (type) => {
    if (selectedFeature) {
      if (type === "down") handleFeatureClick(selectedFeature.id);

      if (type === "up") handleUpwardTrace(selectedFeature.id);

      if (type === "start") setTraceBetween([selectedFeature]);

      if (type === "end")
        setTraceBetween((prevArray) => [...prevArray, selectedFeature]);

      if (type === "length") {
        if (points.length > 1 && !isMeasuring) {
          clearLengthMapping();
        } else {
          setIsMeasuring((prevState) => !prevState);
          if (!isMeasuring)
            setPoints((prevPoints) => [
              ...prevPoints,
              L.latLng(
                selectedFeature.geometry.coordinates[1],
                selectedFeature.geometry.coordinates[0]
              ),
            ]);

          // setMeasuringPoint(
          //   L.latLng(
          //     selectedFeature.geometry.coordinates[1],
          //     selectedFeature.geometry.coordinates[0]
          //   )
          // );
        }
      }
    } else {
      alert("Please select any point element.");
    }
  };

  const onFeatureEachData = (feature, layer) => {
    layer
      .on({
        click: () => handleFeatureClick(feature.properties.id), //change
      })
      .setStyle({
        color: lineColor, // Use the lineColor state for the color
      });
  };

  // const formatPopupContent = (data) => {
  //   return Object.entries(data)
  //     .map(([key, value]) => {
  //       if (value === null) value = "N/A"; // Handling null values
  //       return `<p class=""><span class="text-secondary">${key.replace(
  //         /_/g,
  //         " "
  //       )}: </span><span class="word-break-all">${value}</span></p>`;
  //     })
  //     .join("");
  // };

  const toggleLeftSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleFornaxHomeClick = () => {
    const storedAuth = localStorage.getItem("_auth");
    const url = storedAuth ? process.env.REACT_APP_MAP_VIEW_URL + `/?q=${storedAuth}` : 'https://map.fornaxenergytech.com/dashboard';
    window.open(url);
  };


  const handleLayerChange = (layerName) => {
    setActiveLayer(layerName);
  };

  function onEachGeomDataFeature(feature, layer) {
    // if (feature.properties && feature.properties.label) {
    //     layer.bindTooltip(feature.properties.label, {
    //         className: "custom-tooltip",
    //         permanent: true,  // Make the tooltip always visible
    //         direction: 'auto'  // Automatically positions the tooltip in a sensible way
    //     });
    // }
  }

  const toggleObjectElement = (elementName) => {
    setVisibleElements((prevState) => ({
      ...prevState,
      [elementName]: !prevState[elementName],
    }));
  };

//   const customIcon2 = new L.DivIcon({
//     className: 'custom-div-icon',
//     html: ` <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="lightblue" stroke="blue" stroke-width="2">
//     <polygon points="12,2 22,22 2,22" />
//   </svg>
// `,
//     iconSize: [20, 20],
//     iconAnchor: [12, 24],
//   });

  const pointTypesData = {
    "Transformer": "triangleSVG.svg",
    "Fuse": "circleSVG.svg",
    "Gentry": "squareSVG.svg",
    // "HT Pole": "StarSVG.svg",
    "RMU": "pentagon.svg",
    "HT Route Point": "hexagonSVG.svg",
    "RMU with TC": "ellipseSVG.svg",
    "Switch": "diamondSVG.svg",
    "CTPT": "crossSVG.svg",
    "Existing Pole(Double Circuit)": "xSVG.svg",
    // Add mappings for other point types here
  };
  
  const createCustomIcon = (svgFileName) => {
    return L.icon({
      iconUrl: `./${svgFileName}`, // Update with the actual path to your SVG files
      iconSize: [25, 25], // Adjust size as needed
      iconAnchor: [12, 12], // Adjust anchor as needed
    });
  };
  
  // const pointToLayer = (feature, latlng) => {
  //   const pointTypeName = feature.properties.name;
  
  //   // Use switch case to determine the icon
  //   switch (true) {
  //     case pointTypeName in pointTypesData:
  //       console.log("pointTypeName",pointTypeName)
  //       // Create marker for valid point types
  //       return L.marker(latlng, { icon: createCustomIcon(pointTypesData[pointTypeName]) });
  
  //     default:
  //       console.warn(`No icon mapping found for point type: ${pointTypeName}`);
  //       // Return default marker if no mapping is found
  //       return L.marker(latlng);
  //   }
  // };
  const pointToLayer = (feature, latlng) => {
    const pointTypeName = feature.properties.name;
  
    // Use the mapped icon or a default one
    if (pointTypeName && pointTypeName in pointTypesData) {
      // console.log("pointTypeName:", pointTypeName);
      return L.marker(latlng, {
        icon: createCustomIcon(pointTypesData[pointTypeName]),
      });
    } else {
      console.warn(`No icon mapping found for point type: ${pointTypeName}`);
      return L.marker(latlng); // Default marker
    }
  };
  
  
  

  // const pointToLayer = (feature, latlng) => {
  //   console.error("Feature Layer ",feature);
  //   if(feature.properties.name == "Transformer"){
  //     return L.marker(latlng, { icon: customIcon2 });

  //   }else if (feature.properties.name == "RMU with TC"){
  //     return L.marker(latlng, { icon: customIcon2 });

  //   }
  // };
  
  // const mapRef = useRef(null);
  // const captureMapImage = async () => {
  //   if (mapRef.current) {
  //     console.log(mapRef,"mapComponentRef")
  //     // Example using html2canvas library (install it via npm if not already)
  //     const canvas = await html2canvas(mapRef.current);
  //     console.log(canvas,"canvas")
  //     return canvas.toDataURL('image/jpeg');
  //   }
  //   throw new Error('Map component reference not available');
  // };
  // const generateMapPDF = async () => {
  //   console.log("first generateMapPDF")
  //   try {
  //     // Example: Capture map image as base64 or blob
  //     const mapImage = await captureMapImage(); // Implement captureMapImage according to your map component
  //     console.log(mapImage,"first mapImage")

  //     // Example: Generate PDF using a library like jsPDF or similar
  //     const pdf = new jsPDF();
  //     pdf.addImage(mapImage, 'JPEG', 10, 10, 180, 150); // Adjust dimensions and position as needed
  //     pdf.save('map.pdf');

  //     // Return the generated PDF blob if needed
  //     return pdf.output('blob');
  //   } catch (error) {
  //     console.error('Error generating PDF:', error);
  //     throw error;
  //   }
  // };

  // Example function to capture map image (replace with actual implementation)

  //zoom={zoom} maxZoom={30} zoomControl={false} ref={mapRef} center={[data.features[0].geometry.coordinates[1],data.features[0].geometry.coordinates[0]]}


  useEffect(() => {
    // console.log("It is working");
    if (geojsonData && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(geojsonData);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [geojsonData]);


  
  useEffect(() => {
    // console.log("It is working");
    if (geojsonTalukaData && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(geojsonTalukaData);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [geojsonTalukaData]);


  // console.log("THe data",setPointGeometry, )
  // console.log("THe data 1", measureMode)
  // console.log("THe data 2", setMeasureResult)
  // console.log("THe data 3", setDrawnLayers)



const[selectedDraw, setSelectedDraw] = useState();
  const handleSelectedData = (name,geometry) => {
    setSelectedDraw({
      layerName:name,
      geometry:geometry
    });

    // console.log(name,geometry);
  }

// 
  // const[layerVisibility,setLayerVisibility] = useState(false);
const[circleData,setCircleData] = useState();
const[companyData,setCompanyData] = useState();
const[companyGeojson,setCompanyGeojson] = useState();
const [subDivisiongeojson, setSubDivisiongeojson]= useState();
// const[filterEHVJson,setFilterEHVJson] = useState();
// const[eHVLinegeojson,setEHVLinegeojson] = useState();
const[circleGeojson,setCircleGeojson] = useState();
const[divisionGeojson,setDivisionGeojson] = useState();

const[infraStructure,setInfraStructure] = useState([]);

const [organizations, setOrganizations] = useState([]);
const [divisions, setDivisions] = useState([]);


const [subDivisions, setSubDivisions] = useState([]);

const [organization, setOrganization] = useState([]);

const[searchId,setSearchId] = useState();

const[substationIds,setSubstationIds] = useState([]);
const[divisionData,setDivisionData] = useState([]);
const[subDivisionData,setSubDivisionData] = useState([]);
const [lineData, setLineData] = useState(null);
const[lineDataGeoJSON,setLineDataGeoJSON] = useState();

  useEffect(() => {

    // console.error("circleData",circleData);
    if (!circleData || !Array.isArray(circleData) || circleData.length === 0) {
      setCircleGeojson([]);
      console.warn("Invalid circleData:", circleData);
      return;
    }

    const geojsonFeatures1 = circleData
      .filter(data => data.geometry && data.geometry.type === 'MultiPolygon')
      .map(data => ({
        type: 'Feature',
        properties: {
          id: data.id,
          name: data.properties?.organization_name || 'Unknown',
          type: 'Circle'
        },
        geometry: data.geometry
      }));

    if (geojsonFeatures1.length === 0) {
      console.warn("No valid features in circleData.");
      return;
    }

    const geojson = {
      type: 'FeatureCollection',
      features: geojsonFeatures1
    };

    setCircleGeojson(geojson);

  }, [circleData]);

    // console.log("The cOMKPANY Data is",organization);


  useEffect(() => {
    if (!companyData || !Array.isArray(companyData) || companyData.length === 0) {
      setCompanyGeojson([]);
      console.warn("Invalid CompanyData:", companyData);
      return;
    }

    const geojsonFeatures2 = companyData
      .filter(data => data.geometry && data.geometry.type === 'MultiPolygon')
      .map(data => ({
        type: 'Feature',
        properties: {
          id: data.id,
          name: data.properties?.organization_name || 'Unknown',
          type: 'Company'
        },
        geometry: data.geometry
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in circleData.");
      return;
    }

    const geojson = {
      type: 'FeatureCollection',
      features: geojsonFeatures2
    };

    setCompanyGeojson(geojson);

  }, [companyData]);


  useEffect(() => {
    // console.log("Invalid divisionData:", divisionData);

    if (!divisionData || !Array.isArray(divisionData) || divisionData.length === 0) {
      setDivisionGeojson([]);
      console.warn("Invalid divisionData:", divisionData);
      return;
    }

    const geojsonFeatures2 = divisionData
      .filter(data => data.geometry && data.geometry.type === 'MultiPolygon')
      .map(data => ({
        type: 'Feature',
        properties: {
          id: data.id,
          name: data.properties?.organization_name || 'Unknown',
          type: 'SubDivision'
        },
        geometry: data.geometry
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in DivisionData.");
      return;
    }

    const geojson = {
      type: 'FeatureCollection',
      features: geojsonFeatures2
    };

    // console.log("geojson",geojson);
    setDivisionGeojson(geojson);

  }, [divisionData]);



  useEffect(() => {
    console.error("subDivisiongeojson",subDivisionData);
    if (!subDivisionData || !Array.isArray(subDivisionData) || subDivisionData.length === 0) {
      console.warn("Invalid subDivisiongeojson:", subDivisionData);
      return;
    }

    const geojsonFeatures2 = subDivisionData
      .filter(data => data.geometry && data.geometry.type === 'MultiPolygon')
      .map(data => ({
        type: 'Feature',
        properties: {
          id: data.id,
          name: data.properties?.organization_name || 'Unknown',
          type: 'SubDivision'
        },
        geometry: data.geometry
      }));

    if (geojsonFeatures2.length === 0) {
      console.warn("No valid features in circleData.");
      return;
    }

    const geojson = {
      type: 'FeatureCollection',
      features: geojsonFeatures2
    };

    console.error(geojson);
    setSubDivisiongeojson(geojson);

  }, [subDivisionData]);





const[isshowssIds,setIsshowIds] = useState([]);
const[lineprojectids, setLineprojectids] = useState([]);



useEffect(() => {
  const fetchAndFitGeoJSONData = async () => {
    if (isshowssIds.length === 0) {
      setLineDataGeoJSON(null); // Clear GeoJSON data if no IDs are selected
      return;
    }

    setLoading(true); // Set loading to true when fetching data

    try {
      const ids = isshowssIds.length > 3 ? isshowssIds.slice(1) : isshowssIds; 
      console.log("ids"+ids);
      setIsshowIds(ids);
      const formattedIds = ids.map((id) => `'${id}'`).join(",");
      const wfsUrl = `https://geoserver.fornaxenergytech.com/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=Gis_Gujarat:hv_network&outputFormat=application/json&CQL_FILTER=substation_id IN (${formattedIds})`;

      const response = await axios.get(wfsUrl);
      const geoJsonData = response.data;
      // console.log("LIne Data" , geoJsonData);

      setLineDataGeoJSON(geoJsonData); // Update state with the fetched GeoJSON data

      if (mapRef.current) {
        // Create a GeoJSON layer
        const geoJsonLayer = L.geoJSON(geoJsonData);

        // Get the bounds of the GeoJSON layer
        const bounds = geoJsonLayer.getBounds();

        // Fit map bounds to the new GeoJSON layer with animation
        if (bounds.isValid()) {
          mapRef.current.fitBounds(bounds, {
            animate: true, // Enable animation
            duration: 3, // Duration in seconds
          });
        }
      }
    } catch (error) {
      console.error("Error fetching GeoJSON data:", error);
      setLineDataGeoJSON(null); // Clear data on error
    } finally {
      setLoading(false); // Set loading to false once data is fetched or an error occurs
    }
  };

  fetchAndFitGeoJSONData();
}, [isshowssIds]);


const [filteredObject, setFilteredObject] = useState([]);
const[selectedPointType, setSelectedPointType] = useState([]);

const [subMenuSection, setSubMenuSection] = useState(null);




useEffect(() => {
  console.error("Point types", selectedPointType);
  // Ensure required dependencies are available before calling the function
  if (selectedPointType.length > 0) {
    fetchObjectData(isshowssIds,selectedPointType, setFilteredObject);
  }
}, [isshowssIds, selectedPointType]);

  // const[objectgeojson,setObjectgeojson] = useState();





const [gujaratHighwayData, setGujaratHighwayData] = useState(null);


  useEffect(() => {
    // console.log("It is working");
    if (companyGeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(companyGeojson);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

   // Fit map bounds to the new GeoJSON layer
   if (bounds.isValid()) {
    mapRef.current.fitBounds(bounds, {
      animate: true,       // Enable animation
      duration: 1.5,       // Duration of the animation in seconds
      easeLinearity: 0.25, // Adjust the ease effect (optional)
      maxZoom: 15,         // Set a maximum zoom level (optional)
    });
  }
}
  }, [companyGeojson]);


  useEffect(() => {
    if (circleGeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(circleGeojson);
  
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();
  
      // Fit map bounds to the new GeoJSON layer with animation
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds, {
          animate: true,       // Enable animation
          duration: 1.5,       // Duration of the animation in seconds
          easeLinearity: 0.25, // Adjust the ease effect (optional)
          maxZoom: 15,         // Set a maximum zoom level (optional)
        });
      }
    }
  }, [circleGeojson]);
  


  
  useEffect(() => {
    // console.log("It is working");
    if (divisionGeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(divisionGeojson);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds, {
          animate: true,       // Enable animation
          duration: 1.5,       // Duration of the animation in seconds
          easeLinearity: 0.25, // Adjust the ease effect (optional)
          maxZoom: 15,         // Set a maximum zoom level (optional)
        });
      }
    }
  }, [divisionGeojson]);
  
  useEffect(() => {
    // console.log("It is working");
    if (subDivisiongeojson && mapRef.current) {
      // Create a GeoJSON layer
      const geoJsonLayer = L.geoJSON(subDivisiongeojson);
      
      // Get the bounds of the GeoJSON layer
      const bounds = geoJsonLayer.getBounds();

      // Fit map bounds to the new GeoJSON layer
     // Fit map bounds to the new GeoJSON layer
     if (bounds.isValid()) {
      mapRef.current.fitBounds(bounds, {
        animate: true,       // Enable animation
        duration: 1.5,       // Duration of the animation in seconds
        easeLinearity: 0.25, // Adjust the ease effect (optional)
        maxZoom: 15,         // Set a maximum zoom level (optional)
      });
    }
  }
  }, [subDivisiongeojson]);

  // const selectedPointTypes = ['Transformer', 'Fuse']; 

  // useEffect(() => {
    // console.error("zoom:", zoom);

  //   if (zoom > 12) {
  //             setShowGeoJson(true);  // Show GeoJSON if zoom is greater than 15
  //           } else {
  //             setShowGeoJson(false); // Hide GeoJSON if zoom is 15 or below
  //           }
  // }, [zoom]);
  

  useEffect(() =>{ 

    if(subMenuSection == "base-layer"){
      console.error("Base-layer check");
      setBaseLayer(prevBaseLayer => prevBaseLayer.filter(layer => layer !== "Talukas"));
    }


  },[subMenuSection])

  // const [zoomLevel, setZoomLevel] = useState(); // Initial zoom level

  // const handleZoomChange = (map) => {
  //   setZoom(map.getZoom());
  // console.error(map.getZoom());
  // };




  // Function to get the centroid of a polygon or multipolygon
  const getCentroid = (geometry) => {
    let latlngs = [];

    // Check if it's a MultiPolygon
    if (geometry.type === 'MultiPolygon') {
      geometry.coordinates.forEach((polygon) => {
        polygon[0].forEach((coord) => {
          latlngs.push(L.latLng(coord[1], coord[0]));
        });
      });
    } else if (geometry.type === 'Polygon') {
      geometry.coordinates[0].forEach((coord) => {
        latlngs.push(L.latLng(coord[1], coord[0]));
      });
    }

    const bounds = L.latLngBounds(latlngs);
    return bounds.getCenter();
  };

  // Function to handle each feature for labeling and events
  const onEachFeature = (feature, layer) => {
    console.error("Processing feature");

    if (feature.geometry.type === 'MultiPolygon' || feature.geometry.type === 'Polygon') {
      const centroid = getCentroid(feature.geometry);
      const labelText = feature.properties.name || 'Unnamed Polygon';

      // Add label at the centroid after the layer is added to the map
      let labelMarker;
      if (labelText) {
        layer.on('add', function () {
          labelMarker = L.marker(centroid, {
            icon: L.divIcon({
              className: 'polygon-label',
              html: labelText, // Use feature's name as label
              iconSize: [100, 40],
            }),
          }).addTo(layer._map); // Add label to the map
        });

        // Remove the label when the layer is removed
        layer.on('remove', function () {
          if (labelMarker) {
            layer._map.removeLayer(labelMarker); // Remove the label
          }
        });
      }
    }

    // Optional: handle click events on features
    layer.on({
      click: () => {
        layer.setStyle({ zIndex: 2000, fillColor: 'yellow', color: 'black' });
        layer.getElement().classList.add('custom-highlight'); // Add custom class for shadow effect
      },
    });
  };

  // UseEffect to handle layer lifecycle when circleGeojson changes
  useEffect(() => {
    if (!circleGeojson) return;

    // Create a GeoJSON layer for circleGeojson
    const geojsonLayer = L.geoJSON(circleGeojson, {
      style: circleStyle,
      onEachFeature: (feature, layer) => {
        // Get the label for the circleGeojson only
        const labelText = feature.properties.name || 'No Type';

        // Add label when the layer is added
        let labelMarker;
        if (labelText) {
          layer.on('add', function () {
            labelMarker = L.marker(layer.getBounds().getCenter(), {
              icon: L.divIcon({
                className: 'circle-label',
                html: labelText, // Use feature's name as label
                iconSize: [100, 40],
              }),
            }).addTo(layer._map); // Add label to the map
          });

          // Remove the label when the layer is removed
          layer.on('remove', function () {
            if (labelMarker) {
              layer._map.removeLayer(labelMarker); // Remove the label
            }
          });
        }

        // Highlight circle on click
        layer.on({
          click: () => {
            layer.setStyle({ zIndex: 2000, fillColor: 'yellow', color: 'black' });
            // Add a custom CSS class for shadow effect
            layer.getElement().classList.add('custom-highlight');
          },
        });
      },
    });

    // Cleanup previous geojsonLayer when circleGeojson changes
    return () => {
      if (geojsonLayer) {
        geojsonLayer.clearLayers(); // Clear previous layer and labels
      }
    };
  }, [circleGeojson]); // Trigger effect when circleGeojson changes

  // const map = useMap();


//   const handleAreaPrint = () => {
//     console.error("Printing map...");

//     L.easyPrint({
//       title: 'Print Map',
//       position: 'topleft',
//       sizeModes: ['A3Landscape'], // Set to A3 landscape mode
//       exportOnly: true, // Export only without immediate printing
//       filename: 'LeafletMap_A3_Landscape', // Set filename for export
//       hidden: false, // Keep print button visible
//     }).addTo(map); // Add to the map instance
  

//   return (
//     <button onClick={handleAreaPrint} style={{ position: 'absolute', top: 10, left: 10, zIndex: 1000 }}>
//       Print Map
//     </button>
//   );
// }
  // useEffect(()=>{

  // // console.error("FormData from Search ",zoom );
    
  // },[zoom])
  // const lineIconSVG = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="2" y1="12" x2="22" y2="12" stroke="currentColor" stroke-width="2" /></svg>`;
  // const lineIconBase64 = btoa(unescape(encodeURIComponent(lineIconSVG)));
  // const lineIconDataUrl = `data:image/svg+xml;base64,${lineIconBase64}`;
  

  const [maploading, setMapLoading] = useState(false);
  const[success,setSuccess] = useState(false);
  
  const handleExportPDF = () => {
    exportMapAsPDF(mapRef, setMapLoading, setSuccess, lineData);
  };
  


    // console.log("objectgeojso",objectgeojson);
  

  
    // Convert Substation Data to GeoJSON
    useEffect(() => {
      if (subStatation.length > 0) {
        const geojson = {
          type: 'FeatureCollection',
          features: subStatation.map((item) => ({
            type: 'Feature',
            properties: { name: item.label, substation_id:item.ss_id },
            geometry: { type: 'Point', coordinates: [item.lng, item.lat] },
          })),
        };
        setSubStationGeojson(geojson);
        // console.error("GeoJSON Data:", geojson);
      }
    }, [subStatation]);
  

  // // // Debugging log
  // useEffect(() => {
  //   console.log("Shows Id Data Updated:", isshowssIds);

  // }, [isshowssIds]);
   
  // const handleExportAllLayersAsPDF = () => {
  //   exportAllLayersAsPDF(mapRef); // Call the function with mapRef as the argument
  // };
    
    // const[isLineDataChecked, setIsLineDatachecked] = useState();
  

    const getColorForSubstation = (substationId) => {
      
      // console.warn("Substation According to color", substationId)
      return color[substationId] || '#0252A8'; // Default color
    };

    // console.log("Reserve Network", reserverLayerData);
    const[flyname, setFlyname] = useState(null);



 
   
    useEffect(() => {
      if (!mapRef.current) return;
    
      const map = mapRef.current;
    
      if (selectedPointType.length > 0 && filteredObject?.length > 0) {
        const filtered = filteredObject.filter(item =>
          selectedPointType.includes(item.properties.point_type)
        );
    
        const geojsonFeatures2 = filtered
          .filter(data => data.geometry?.type === 'Point')
          .map(data => ({
            type: 'Feature',
            properties: {
              id: data.id,
              name: data.properties?.point_type || 'Unknown',
              type: data.properties?.point_type || 'Unknown',
            },
            geometry: data.geometry,
          }));
    
        if (geojsonFeatures2.length > 0) {
          const geojson = {
            type: 'FeatureCollection',
            features: geojsonFeatures2,
          };
          // setObjectgeojson(geojson);
    
          const clusterGroupKey = `cluster-group-${selectedPointType.sort().join('-')}`;
          let clusterGroup = null;
    
          // Check if the cluster group already exists
          map.eachLayer(layer => {
            if (layer.options?.clusterGroupKey === clusterGroupKey) {
              clusterGroup = layer;
            }
          });
    
          // Create or clear the cluster group
          if (!clusterGroup) {
            clusterGroup = L.markerClusterGroup({ clusterGroupKey });
            map.addLayer(clusterGroup);
          } else {
            clusterGroup.clearLayers();
          }
    
          // Add markers to the cluster group
          const markers = geojson.features.map(feature => {
            const { geometry, properties } = feature;
            const latLng = L.latLng(geometry.coordinates[1], geometry.coordinates[0]);
            const marker = pointToLayer ? pointToLayer(feature, latLng) : L.marker(latLng);
    
            marker.bindPopup(`
              <div style="padding: 10px; background-color: #f9f9f9; border-radius: 8px;">
                <h5 style="font-size: 16px; font-weight: 600;">Location: <span>${properties.name}</span></h5>
                <div>ID: ${properties.id}</div>
                <div>Coordinates: ${latLng.lat.toFixed(5)}, ${latLng.lng.toFixed(5)}</div>
              </div>
            `);
    
            return marker;
          });
    
          clusterGroup.addLayers(markers);
    
          // Cleanup function
          return () => {
            map.eachLayer(layer => {
              if (layer.options?.clusterGroupKey === clusterGroupKey) {
                map.removeLayer(layer);
              }
            });
          };
        } else {
          // setObjectgeojson([]);
        }
      } else {
        // setObjectgeojson([]);
        map.eachLayer(layer => {
          if (layer.options?.clusterGroupKey) {
            map.removeLayer(layer);
          }
        });
      }
    }, [selectedPointType, filteredObject, mapRef, pointToLayer]);
    
    
    
    const [isAnimating, setIsAnimating] = useState(false);

    const handleRotateAndVibrate = () => {
      setIsAnimating(true); // Start animation
  
      setTimeout(() => {
        setIsAnimating(false); // Stop animation
      }, 1500); // Complete after 1 second
      setTimeout(() => { window.location.reload(); },500) ;
    };

    return (
    <>
    {/* <PrintControl geojsonData={circleGeojson}/> */}

    {/* {eHVLinegeojson} */}
      <TopNavbar sendDataToParent={handleDataFromChild}
      
      organizations={organizations}
       setOrganizations={setOrganizations}
       divisions={divisions} 
       setDivisions={setDivisions}
       subDivisions={subDivisions} 
       setSubDivisions={setSubDivisions}

       setSearchId={setSearchId}
       searchData={searchData}
        setSearchData={setSearchData}

      />
      <div className="app" style={{ position: "relative" }}>
        <div
          className={`sidebar leftsidebar-container ${
            sidebarVisible ? "visible" : "hidden"
          }`}
        >
          <LeftSidebar
            sendDataToParent={handleDataFromChild}
            setMapLayer={handleLayerChange}
            updateCenter={calculateCenter}
            addMerkers={setMarkers}
            handleBaseLayerChange={handleBaseLayerChange}
            baseLayer={baseLayer}
            errorMessage={errorMessage}
  isTalukaVisible={isTalukaVisible}
  handleRefresh={handleRefresh}
  searchData={searchData}
  setCircleData={setCircleData}
    // setPointDataElements={setPointDataElements}
    setInfraStructure={setInfraStructure}
    infraStructure={infraStructure}
    handleClearNetwork={handleClearNetwork}
    setDistrictStyle={setDistrictStyle}
    setTalukaStyle={setTalukaStyle}
    setCompanyStyle={setCompanyStyle}
    companyStyle={companyStyle}
    setCompanyData={setCompanyData}
    setSubstationIds={setSubstationIds}
    setHighwayStyle={setHighwayStyle}
    organization={organization}
    setOrganization={setOrganization}
    organizations={organizations}
    setOrganizations={setOrganizations}
    divisions={divisions} 
    setDivisions={setDivisions}
    subDivisions={subDivisions} 
    setSubDivisions={setSubDivisions}
    setDivisionData={setDivisionData}
    setSubDivisionData={setSubDivisionData}
    setCircleStyle={setCircleStyle}
    setDivisionStyle={setDivisionStyle}
    setSubDivisionStyle={setSubDivisionStyle}
    circleStyle={circleStyle}
    divisionStyle={divisionStyle}
    subDivisionStyle={subDivisionStyle}
    talukaStyle={talukaStyle}
    districtStyle={districtStyle}
    highwayStyle={highwayStyle}
    measureDetail={measureDetail}
  selectedPointType={selectedPointType} 
  setSelectedPointType={setSelectedPointType}
  subMenuSection={subMenuSection}
  setSubMenuSection={setSubMenuSection}
  handleClearLineNetwork={handleClearLineNetwork}
  clearnetwork={clearnetwork}
  handleAreaPrint={handleExportPDF}
  maploading={maploading}
  success={success}
  setGujaratHighwayData={setGujaratHighwayData}
    measureMode={measureMode} 
    handleMeasureChange={handleMeasureChange}
    setIsshowIds={setIsshowIds}
    isshowssIds={isshowssIds}
          waterStyle={waterStyle}
          setwaterStyle={setwaterStyle}      
setWaterLayerData={setWaterLayerData}
setRailwayLayerData={setRailwayLayerData}
setReserverLayerData={setReserverLayerData}

reserveStyle={reserveStyle}
setReserveStyle={setReserveStyle}
railStyle={railStyle}
setRailStyle={setRailStyle}

setIsGujaratBoundary={setIsGujaratBoundary}
isGujaratBoundary={isGujaratBoundary}
transformerCheckedIds={transformerCheckedIds}
  setTransformerCheckedIds={setTransformerCheckedIds}
  subStatation={subStatation}
   setSubStatation={setSubStatation}
   setFlyname={setFlyname}

          />
        </div>
        <div className="main-content">
          <button className="toggle-switch" onClick={toggleLeftSidebar}>
            <img className="bars-icon" src="menu.png" />
          </button>
          <button
              onClick={handleFornaxHomeClick}
              className="home-switch"
          >
              <img className="bars-icon" src="home.png" />
          </button>


          <button
          
  onClick={handleRotateAndVibrate} // Corrected reload function
  className="home-switch"
>
  <img   className={`icon-container ${isAnimating ? "animate" : ""}`}  src="reload.svg" alt="Reload Icon" />
</button>

          <MapContainer
            zoom={zoom}
            zoomControl={false}
            ref={mapRef}
            center={center}
            maxBounds={gujaratBounds}
            minZoom={8}
          >

<ZoomTracker setZoom={setZoom} />

{isGujaratBoundary &&(
<WMSTileLayer
         url="https://geoserver.fornaxenergytech.com/geoserver/wfs"
         layers={`Gis_Gujarat:District`}
         format="image/png"
         transparent={true}
         opacity={0.7}
         attribution="InfraStructure Layer"
         zIndex={10000}
       />)}


{subStationGeojson && (
<MarkerCluster data={subStationGeojson} flyname={flyname} setIsshowIds={setIsshowIds} isshowssIds={isshowssIds} color={color} setColor={setColor} />

)}



      {gujaratHighwayData && (
        <GeoJSON
        key={JSON.stringify(gujaratHighwayData)}
          data={gujaratHighwayData}
          renderer={L.canvas()}  // Use canvas for faster rendering
          style={highwayStyle}
          onEachFeature={(feature, layer) => {
            if (feature.properties && feature.properties.name) {
              layer.bindPopup(feature.properties.name);
            }
          }}
        />
      )}


{waterLayerData && (
        <GeoJSON
        key={JSON.stringify(waterLayerData)}
          data={waterLayerData}
          renderer={L.canvas()}  // Use canvas for faster rendering

          style={waterStyle}
          onEachFeature={(feature, layer) => {
            if (feature.properties && feature.properties.name) {
              layer.bindPopup(feature.properties.name);
            }
          }}
        />
      )}

{reserverLayerData && (
        <GeoJSON
        key={JSON.stringify(reserverLayerData)}
          data={reserverLayerData}
          renderer={L.canvas()}  // Use canvas for faster rendering
                    style={reserveStyle}
          onEachFeature={(feature, layer) => {
            if (feature.properties && feature.properties.name) {
              layer.bindPopup(feature.properties.name);
            }
          }}
        />
      )}


{railwayLayerData && (
        <GeoJSON
        key={JSON.stringify(railwayLayerData)}
          data={railwayLayerData}
          renderer={L.canvas()}  // Use canvas for faster rendering

          style={railStyle}
          onEachFeature={(feature, layer) => {
            if (feature.properties && feature.properties.name) {
              layer.bindPopup(feature.properties.name);
            }
          }}
        />
        
      )}


     {loading &&
     
    <Loader loading={loading} />
     
    }

  {lineDataGeoJSON && (
        <GeoJSON
          key={JSON.stringify(lineDataGeoJSON)} // Force re-render when data changes
          data={lineDataGeoJSON}
          renderer={L.canvas()}  // Use canvas for faster rendering
          // style={geoJSONStyle} // Use memoized style function
          style={(feature) => {
           
          
            const substationId = feature.properties.substation_id;
            return {
              color: getColorForSubstation(substationId),
              weight: 2,
              opacity: 0.9
            }; }}
        />
      )}
 


{measurementVisible &&(
    <MapEvents
          setPointGeometry={setPointGeometry}
          measureMode={measureMode}
          setMeasureResult={setMeasureResult}
          setDrawnLayers={setDrawnLayers}
          setSelectedData={setSelectedData}
          measureDetail={measureDetail}
          setMeasureDetail={setMeasureDetail}
        />
       )} 


  
{ circleGeojson && (
  <GeoJSON
    key={JSON.stringify(circleGeojson)}
    data={circleGeojson}
    style={circleStyle}

    onEachFeature={onEachFeature}
  />
)}


{ companyGeojson && (

<GeoJSON
key={JSON.stringify(companyGeojson)}
data={companyGeojson}
style={companyStyle}
 
  onEachFeature={onEachFeature}
/>
)}

{divisionGeojson && (

<GeoJSON
key={JSON.stringify(divisionGeojson)}
data={divisionGeojson}
style={divisionStyle}
  onEachFeature={onEachFeature}
/>
  )
}

{subDivisiongeojson && (

<GeoJSON
key={JSON.stringify(subDivisiongeojson)}
data={subDivisiongeojson}
style={subDivisionStyle}
  onEachFeature={onEachFeature}
/>
  )
}


                  {geojsonData && isMapVisble && (
          <GeoJSON
          key={geojsonData.id}
            data={geojsonData}
          renderer={L.canvas()}  // Use canvas for faster rendering

          style={districtStyle}
    

            onEachFeature={onEachFeature}
          />
        )}

        {geojsonTalukaData && isMapVisble && (
           <GeoJSON
           key={JSON.stringify(geojsonTalukaData)}
           data={geojsonTalukaData}
          renderer={L.canvas()}  // Use canvas for faster rendering

           style={talukaStyle}
    

           onEachFeature={onEachFeature}
         />

        )} 

            <LayersControl>
              <ZoomControl position="bottomright" />
              <BaseLayer checked={activeLayer === "empty"} name="Empty">
                <TileLayer
                  url="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                  maxZoom={30}
                  attribution=""
                />
              </BaseLayer>

              <BaseLayer
                checked={activeLayer === "OpenStreetMap"}
                name="OpenStreetMap"
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  maxZoom={30}
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
      
                
              </BaseLayer>

              <BaseLayer checked={activeLayer === "Satellite"} name="Satellite">
                <TileLayer
                  url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                  maxZoom={20}
                  subdomains={["mt1", "mt2", "mt3"]}
                />
              </BaseLayer>

              <BaseLayer checked={activeLayer === "BingMaps"} name="Bing Maps">
                <BingLayer
                  bingkey="AuhiCJHlGzhg93IqUH_oCpl_-ZUrIE6SPftlyGYUvr9Amx5nzA-WqGcPquyFZl4L"
                  type="AerialWithLabels"
                  maxNativeZoom={19}
                />
              </BaseLayer>
            </LayersControl>
            {/* {markers && markers.map((marker) => (
              <div key={marker.value}>
                <Marker position={[marker.lng, marker.lat]} icon={customIcon}>
                  <Tooltip
                    className="custom-tooltip"
                    direction="bottom"
                    permanent
                  >
                    {marker.label}
                  </Tooltip>
                </Marker>
                <GeoJSON
                  data={JSON.parse(marker.feature)}
                  onEachFeature={onEachGeomDataFeature}
                />
              </div>
            ))} */}
            <SetViewToBounds markers={markers} />
{/* 
            {circles && circles.map((circle, idx) => (
              <Circle
                key={idx}
                center={circle.latlng}
                radius={circle.radius}
                color={circle.color}
                fillColor={circle.fillColor}
                fillOpacity={circle.fillOpacity}
              />
            ))}
            {traceBetween && traceBetween.map((point, idx) => (
              <Circle
                key={idx}
                center={[
                  point.geometry.coordinates[1],
                  point.geometry.coordinates[0],
                ]}
                radius="10"
                color="red"
                fillOpacity="0"
              />
            ))} */}
            {/* {isMeasuring && <Marker position={measuringPoint}></Marker>} */}
            {/* {isMeasuring && cursorPosition && (
              <>
                <Polyline
                  positions={[measuringPoint, cursorPosition]}
                  color="black"
                />
                {/* <Marker position={midpoint} icon={distanceText}></Marker> */}
              {/* </> */}
            {/* )} */} 
            {/* {points.length > 1 && (
              <>
                <Polyline positions={points} color="black">
                  {points.map((point, index) => {
                    if (index === 0) return null;
                    return (
                      <Tooltip
                        key={index}
                        direction="center"
                        offset={[0, 0]}
                        opacity={1}
                        permanent
                      >
                        <span>
                          Total Distance: {totalDistance.toFixed(2)} km
                        </span>
                        <br />
                      </Tooltip>
                    );
                  })}
                </Polyline>


              </>
            )} */}
            {/* {isMeasuring && <MyComponent />} */}
          </MapContainer>


     
        </div>

        <div className="sidebar right-sidebar-container">
        { subMenuSection == "base-layer" || subMenuSection == "objects" ?  (
          <>
            {/* <RightSideBaseLayer
           activeBaseLayer={activeBaseLayer}
           selectedTaluka={selectedTaluka}
           isDistrictVisble={isDistrictVisble}
           isTalukaVisible={isTalukaVisible}
           setGeojsonData={setGeojsonData}
           setGeojsonTalukaData={setGeojsonTalukaData}
           setVillages={setVillages}
           /> */}

          <RightSideDrawBar
          setErrorMessage={setErrorMessage}
          baseLayer={baseLayer}
          // activeBaseLayer={activeBaseLayer}
          selectedTaluka={selectedTaluka}
          // isDistrictVisble={isDistrictVisble}
          isTalukaVisible={isTalukaVisible}
          setGeojsonData={setGeojsonData}
          setGeojsonTalukaData={setGeojsonTalukaData}
          // setVillages={setVillages}
          setMeasureMode={setMeasureMode}
          measureMode={measureMode}
          handleMeasureChange={handleMeasureChange}
          // setIsDistrictVisible={setIsDistrictVisible}
          selectedData={selectedData}
          measureResult={measureResult}
          setIsTalukaVisible={setIsTalukaVisible}

          setSelectedDistrictId={setSelectedDistrictId}
          selectedDistrictId={selectedDistrictId}
          subMenuSection={subMenuSection}
          measureDetail={measureDetail}
          // setIsTalukaVisible={setIsTalukaVisible}
          
          />
         </>
          ) : (
            ""
          )}
      
          {/* {
          subMenuSection == "organization"?  (
            <RightSidebar
              selectedFeature={featureProperties}
              setOpenedMenuOption={openedRightMenuOption}
              sendTracingData={handleTracing}
              networkDetails={networkDetails}
              getidInRighSidebar={getidInRighSidebar}
   
            />
          ) : (
            ""
          )} */}


{/*  */}
        </div>
       
      </div>
    </>
  );
};




const SetViewToBounds = ({ markers }) => {
  const map = useMap(); // This hook provides the map instance

  useEffect(() => {
    if (markers.length > 0) {
      const bounds = L.latLngBounds(
        markers.map((marker) => L.latLng(marker.lng, marker.lat))
      );
      map.fitBounds(bounds);
    }
  }, [markers, map]); // map is a stable object and does not cause re-renders

  return null; // This component does not render anything
};





export default MapComponent;
