import React, { useState, useRef, useEffect, useCallback } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-easyprint/dist/bundle";
import "leaflet-geometryutil";

const MapEvents = ({
  measureMode,
  setMeasureResult,
  setSelectedData,
  setMeasureDetail,
}) => {
  const map = useMap();

  const drawnItems = useRef(new L.FeatureGroup());
  const [drawing, setDrawing] = useState(false);
  const [currentLayer, setCurrentLayer] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [counter, setCounter] = useState({ point: 0, length: 0, area: 0, circle: 0 });
  const [layerNames, setLayerNames] = useState(new Map());

  // Initialize drawnItems feature group
  useEffect(() => {
    map.addLayer(drawnItems.current);
    return () => {
      map.removeLayer(drawnItems.current);
    };
  }, [map]);

  // Get layer name based on type
  const getLayerName = useCallback((type) => {
    let name = '';
    switch (type) {
      case 'point':
        name = `Point ${String.fromCharCode(65 + counter.point)}`;
        setCounter((prev) => ({ ...prev, point: prev.point + 1 }));
        break;
      case 'length':
        name = `Length ${String.fromCharCode(65 + counter.length)}`;
        setCounter((prev) => ({ ...prev, length: prev.length + 1 }));
        break;
      case 'area':
        name = `Area ${String.fromCharCode(65 + counter.area)}`;
        setCounter((prev) => ({ ...prev, area: prev.area + 1 }));
        break;
      case 'circle':
        name = `Circle ${String.fromCharCode(65 + counter.circle)}`;
        setCounter((prev) => ({ ...prev, circle: prev.circle + 1 }));
        break;
      default:
        break;
    }
    return name;
  }, [counter]);

  // Handle layer click
  const handleLayerClick = useCallback(({ layer, name, geom, measureMode }) => {
    if (selectedLayer && selectedLayer._leaflet_id === layer._leaflet_id) {
      drawnItems.current.removeLayer(layer);
      setSelectedLayer(null);
    } else {
      if (selectedLayer) {
        selectedLayer.setStyle({ color: '#3388ff' });
      }
      layer.setStyle({ color: 'red' });
      setSelectedLayer(layer);

          // Calculate measurement based on the layer type
          let measurement = null;
          let type = '';
          
          if (layer instanceof L.Polygon) {
            type = 'area';
            // Calculate area in square kilometers
            measurement = (L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 1e6).toFixed(3); // Convert to km² and round to 3 decimal places
          } else if (layer instanceof L.Polyline) {
            type = 'length';
            // Calculate length in kilometers
            measurement = (L.GeometryUtil.length(layer.getLatLngs()) / 1000).toFixed(3); // Convert to km and round to 3 decimal places
          } else if (layer instanceof L.Marker) {
            type = 'point';
            // Get latitude and longitude
            const latlng = layer.getLatLng();
            measurement = {
              latitude: latlng.lat.toFixed(3),
              longitude: latlng.lng.toFixed(3),
            };
          } else if (layer instanceof L.Circle) {
            type = 'circle';
            // Calculate area in square kilometers
            const radiusInKm = layer.getRadius() / 1000; // Convert to kilometers
            measurement = (Math.PI * radiusInKm * radiusInKm).toFixed(3); // Area in km² and round to 3 decimal places
          }
      
    

      const detail = { name, geom , type , measurement};
      setMeasureDetail(detail);

      // console.log(detail)
    }
  }, [selectedLayer, setMeasureDetail]);

  // Handle drawing
  useEffect(() => {
    if (!measureMode) return;

    if (drawing) finalizeCurrentLayer();

    const drawControl = new L.Control.Draw({
      position: 'topleft',
      edit: {
        featureGroup: drawnItems.current,
        poly: { allowIntersection: false },
      },
      draw: {
        polyline: measureMode === 'length',
        polygon: measureMode === 'area',
        circle: measureMode === 'circle',
        marker: measureMode === 'point',
        rectangle: false,
        circlemarker: false,
      },
    });

    map.addControl(drawControl);

    map.on(L.Draw.Event.CREATED, (e) => {
      const layer = e.layer;
      const name = getLayerName(measureMode);

      layer.bindTooltip(name, { permanent: true, direction: 'top' }).addTo(map);
      drawnItems.current.addLayer(layer);
      setLayerNames((prev) => new Map(prev).set(layer._leaflet_id, name));

      const latlngs = layer instanceof L.Polygon ? layer.getLatLngs()[0] : layer.getLatLngs();
      const geom = createGeometry(latlngs, measureMode);
      layer.on('click', () => handleLayerClick({ layer, name, geom,measureMode }));

      setSelectedData({ layername: name, geometry: geom });
      setCurrentLayer(layer);
    });

    return () => {
      map.removeControl(drawControl);
      map.off(L.Draw.Event.CREATED);
    };
  }, [map, measureMode, drawing, getLayerName, handleLayerClick, setSelectedData]);

  // Finalize current layer
  const finalizeCurrentLayer = useCallback(() => {
    if (currentLayer) {
      setDrawing(false);
      setCurrentLayer(null);
    }
  }, [currentLayer]);

  // Create geometry in WKT format
  const createGeometry = useCallback((latlngs, type) => {
    let geom = "";
    if (type === "length") {
      geom = `LINESTRING(${latlngs.map(({ lat, lng }) => `${lng.toFixed(6)} ${lat.toFixed(6)}`).join(', ')})`;
    } else if (type === "point") {
      geom = `POINT(${latlngs[0].lng.toFixed(6)} ${latlngs[0].lat.toFixed(6)})`;
    } else if (type === "area") {
      const closedLatlngs = [...latlngs, latlngs[0]]; // Close the polygon
      geom = `POLYGON((${closedLatlngs.map(({ lat, lng }) => `${lng.toFixed(6)} ${lat.toFixed(6)}`).join(', ')}))`;
    } else if (type === "circle") {
      const center = latlngs[0];
      geom = `POINT(${center.lng.toFixed(6)} ${center.lat.toFixed(6)})`;
    }
    return geom;
  }, []);

  return null;
};

export default MapEvents;
